import * as THREE from "three";
import {Euler, Vector3} from "three";
import {ANNOTATION_LOCATION} from "./GlobalConstants";

export  const clientOptions = {
    useGUI: 'N',
    initCameraOrient: {
        position: new Vector3(-.932, -2.107, 8.887),
        rotation: new Euler(0, 1.288, 0, 'XYZ')

    },
    gammaOutput: 'N', //ignore for now
    gammaInput: 'Y', //ignore for now
    usePaintingsIdForEncoding: 'N', //if 'Y', then apply  paintingTextureEncoding setting to each painting tagged with painting_id
    exposure: .73,
    textureEncoding: 'sRGB',  //'Linear', //sRGB',
    paintingTextureEncoding: 'sRGB', //'Linear', //'sRGB',
    physicallyCorrectLights: 'Y', //if not presented in the Viewer.js then must be set to 'Y'
    fov: 55, //Camera field of view
    outputEncoding:'sRGB' , //'Linear' or 'sRGB',
    envMapIntensity: 1, // if envMap is not used (colorMap = 0) then this property has nos no effect
    envMap: {
        colorMap: 2, //envmap for light. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
        backgroundMap: 0, //envmap for background image. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
    },
    modelName:'NC_release_V01.10.glb',
};

export const DEFAULT_ANNOTATION_LOCATION = ANNOTATION_LOCATION.TOP_RIGHT; // can be one of ANNOTATION_LOCATION. Default is TOP_LEFT

export const DEFAULT_ANNOTATION_LOCATION_VERT_OFFSET = 0 // in meters. vertical offset. default is 0.0

export const DEFAULT_ANNOTATION_LOCATION_HORIZ_OFFSET = 0 // in meters. Horizontal offset. default is 0.0

export const DEFAULT_ANNOTATION_LOCATION_Z_OFFSET = 0.1 // in meters. Z offset. default is 0.1

//Rana comments: this decides the size of the annotation buttons in 3D world. Don't change the z-axes
export const DEFAULT_ANNOTATION_SCALE = new THREE.Vector3(.05,.05,1);// export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI.svg'

export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI_grey.svg'
export const SPRITE_TONEMAPPED = false; //will effect the custom sprite defined in AnnotationDB
export const SPRITE_COLOR = 0x999999; // RGB color - will effect the custom sprite defined in AnnotationDB
export const SPRITE_TEXTURE_ENCODING = THREE.sRGBEncoding; //THREE.LinearEncoding;   //will effect the custom sprite defined in AnnotationDB

export const CAMERA_LOCATION_OFFSET = 1; //how far camera will offset when using camera hoping

export const ZONE_LINKS_LABEL = 'CLICK TO VIEW ZONE';
export const TELEPORTPOINT_DISTANCE_RATIO = 0.5;
export const COLLIDER_DISTANCE_RATIO = .75;

export const DISPLAY_ZONE_LINK = true;
