import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>
            <VideoComponent id="Z0V1" src={process.env.PUBLIC_URL + `/videos/Z0V1.mp4`}/>

            <VideoComponent id="Z1V1" src={process.env.PUBLIC_URL + `/videos/Z1V1.mp4`}/>

            <VideoComponent id="Z4V1" src={process.env.PUBLIC_URL + `/videos/Z4V1.mp4`}/>

            <VideoComponent id="Z5V1" src={process.env.PUBLIC_URL + `/videos/Z5V1.mp4`}/>

            <VideoComponent id="Z6V1" src={process.env.PUBLIC_URL + `/videos/Z6V1.mp4`}/>

            <VideoComponent id="Z9V1" src={process.env.PUBLIC_URL + `/videos/Z9V1.mp4`}/>

            <VideoComponent id="Z14V1" src={process.env.PUBLIC_URL + `/videos/Z14V1.mp4`}/>
            <VideoComponent id="Z14V2" src={process.env.PUBLIC_URL + `/videos/Z14V2.mp4`}/>
            <VideoComponent id="Z14V3" src={process.env.PUBLIC_URL + `/videos/Z14V3.mp4`}/>

            <VideoComponent id="Z9V5a" src={process.env.PUBLIC_URL + `/videos/Z9V5a.mp4`}/>
            <VideoComponent id="Z9V5b" src={process.env.PUBLIC_URL + `/videos/Z9V5b.mp4`}/>
            <VideoComponent id="Z9V5c" src={process.env.PUBLIC_URL + `/videos/Z9V5c.mp4`}/>
            <VideoComponent id="Z9V5d" src={process.env.PUBLIC_URL + `/videos/Z9V5d.mp4`}/>

            <VideoComponent id="Z9V6a" src={process.env.PUBLIC_URL + `/videos/Z9V6a.mp4`}/>
            <VideoComponent id="Z9V6b" src={process.env.PUBLIC_URL + `/videos/Z9V6b.mp4`}/>
            <VideoComponent id="Z9V6c" src={process.env.PUBLIC_URL + `/videos/Z9V6c.mp4`}/>
            <VideoComponent id="Z9V6d" src={process.env.PUBLIC_URL + `/videos/Z9V6d.mp4`}/>
            <VideoComponent id="Z9V6e" src={process.env.PUBLIC_URL + `/videos/Z9V6e.mp4`}/>
            <VideoComponent id="Z9V6f" src={process.env.PUBLIC_URL + `/videos/Z9V6f.mp4`}/>
            <VideoComponent id="Z9V6g" src={process.env.PUBLIC_URL + `/videos/Z9V6g.mp4`}/>
            <VideoComponent id="Z9V6h" src={process.env.PUBLIC_URL + `/videos/Z9V6h.mp4`}/>
            <VideoComponent id="Z9V6i" src={process.env.PUBLIC_URL + `/videos/Z9V6i.mp4`}/>

            <VideoComponent id="Z10V5d" src={process.env.PUBLIC_URL + `/videos/Z10V5d.mp4`}/>
            <VideoComponent id="Z8V1" src={process.env.PUBLIC_URL + `/videos/Z8V1.mp4`}/>

            <VideoComponent id="Z14V4" src={process.env.PUBLIC_URL + `/videos/Z14V4.mp4`}/>
            <VideoComponent id="Z0V02" src={process.env.PUBLIC_URL + `/videos/Z0V02.mp4`}/>


        </div>
    )
}

export default VideosComponent;
