
import {Euler, Vector3,Scene} from "three";

const FLOOR_NUMBER = {
  BOTTOM_FLOOR: 1,
  TOP_FLOOR:2,
};

const CameraLocationsDB = [

    {
        cameraLocationId: 'Z00',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'ENTRANCE',
        cameraPosition: new Vector3(-.932, -2.107, 8.887), // use THREE.Vector3
        cameraRotation: new Euler(0, 1.288, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'Z01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Building upon Building',
        cameraPosition: null, // use THREE.Vector3
        cameraRotation: null,  //use THREE.Euler
    },
    {
        cameraLocationId: 'Z02',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Remedial Housing',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z03',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Ghost in the Machine',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z04',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Future City',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z05',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Rituals',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z06',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'House of Memories',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z07',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Ordinary Resilience',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z08',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Legacies of Modernism',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z090',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'FIRST FLOOR',
        cameraPosition: new Vector3(-1.927, 1.520, 0.145), // use THREE.Vector3
        cameraRotation: new Euler(-3.142, 0.000, -3.142),  //use THREE.Euler
    },
    {
        cameraLocationId: 'Z09',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Belfast Excavations',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z10',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Archiving the City',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z11',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Cultural Assemblages',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z12',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Material Change',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z13',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Architecture & Urban Planning',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z14',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Remapping the Neo Avant Garde',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'Z15',
        floorNumber: FLOOR_NUMBER.TOP_FLOOR,
        locationName: 'Oikos Transformed',
        cameraPosition: null,
    },

];

export {CameraLocationsDB, FLOOR_NUMBER};
